<script>
import CategoryModal from "./components/ProductCategoryModal";
import ApiService from "@services/api.service";

export default {
  name: "ProductCategory",
  components: {
    CategoryModal
  },
  page: {
    title: "Product Category"
  },
  data() {
    return {
      table: {
        sortBy: "description",
        fields: [
          { key: "description", sortable: true, label: "Product Category" },
          { key: "status", class: "text-right" },
          { key: "actions", class: "text-right" }
        ],
        data: [],
        filter: null
      },

      showModal: false,
      productCategory: {
        description: null
      }
    };
  },
  computed: {
    rowCount: function () {
      return this.table.data.length;
    }
  },
  created() {
    this.loadTable();
  },
  methods: {
    loadTable() {
      this.changeLoader(true);
      ApiService.get("product/category")
        .then(resp => {
          this.table.data = resp;
        })
        .finally(() => {
          this.changeLoader(false);
        });
    },
    openDetail(selectedRow) {
      this.productCategory = { ...selectedRow };
      this.showModal = true;
    },
    closeDetail() {
      this.showModal = false;
    },
    toggle(code) {
      this.changeLoader(true);
      let qs = {
        code: code
      };
      ApiService.patch("product/category", null, qs)
        .then(() => {
          this.loadTable();
        })
        .finally(() => {
          this.changeLoader(false);
        });
    },
    clearParent() {
      this.productCategory = {
        description: null
      };
    }
  }
};
</script>

<template>
  <div>
    <b-card>
      <div class="d-flex mb-2">
        <b-btn variant="outline-primary" @click.stop="openDetail(null)">Create</b-btn>
        <div class="ml-auto">
          <b-input v-model="table.filter" placeholder="Filter" class="form-control text-right" />
        </div>
      </div>

      <b-table
        :items="table.data"
        :fields="table.fields"
        :sort-by.sync="table.sortBy"
        :filter="table.filter"
        no-provider-filtering
        empty-text="No data to show"
        striped
        outlined
        hover
        show-empty
        fixed
      >
        <template #cell(status)="data">
          <div>
            <b-badge :variant="data.item.isActive ? 'success' : 'warning'" class="text-capitalize">
              {{ data.item.isActive ? "Active" : "Inactive" }}
            </b-badge>
          </div>
        </template>
        <template #cell(actions)="data">
          <div>
            <b-btn class="btn btn-sm btn-wide mr-1" :class="data.item.isActive ? 'btn-secondary' : 'btn-info'" @click.stop="toggle(data.item.code)">{{
              data.item.isActive ? "Deactivate" : "Activate"
            }}</b-btn>
            <b-btn class="btn btn-sm btn-wide btn-warning mr-1" @click.stop="openDetail(data.item)">Edit</b-btn>
            <!-- <b-btn class="btn btn-sm btn-wide btn-danger" @click.stop="openDetail(data.item)">Delete</b-btn> -->
          </div>
        </template>
      </b-table>
    </b-card>

    <CategoryModal :is-shown="showModal" :category-data="productCategory" @closeModal="showModal = false" @saveSuccess="loadTable()" />
  </div>
</template>
