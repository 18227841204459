<script>
import ApiService from "@services/api.service";

export default {
  name: "ProductCategoryModal",
  props: {
    isShown: {
      type: Boolean,
      required: true,
      default: false
    },
    categoryData: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      productCategoryList: null,
      payload: {
        code: null,
        description: null
      }
    };
  },
  computed: {
    showLocal: {
      get() {
        return this.isShown;
      },
      set(val) {
        if (!val) {
          this.$emit("closeModal");
        }
      }
    },
    editMode: function () {
      return this.categoryData == null ? false : this.categoryData.code != null;
    }
  },
  watch: {
    categoryData(val) {
      if (val) {
        this.payload = val;
      }
    }
  },
  mounted() {},
  methods: {
    save() {
      if (!this.editMode) {
        this.changeLoader(true);
        ApiService.post("product/category", this.payload)
          .then(() => {
            this.showToast("success", "Create Successful");
            this.showLocal = false;
            this.$emit("saveSuccess");
          })
          .finally(() => {
            this.changeLoader(false);
          });
      } else {
        this.changeLoader(true);
        ApiService.put("product/category", this.payload)
          .then(() => {
            this.showToast("success", "Edit Successful");
            this.showLocal = false;
            this.$emit("saveSuccess");
          })
          .finally(() => {
            this.changeLoader(false);
          });
      }
    },
    clearForm() {
      this.payload = { description: null };
    }
  }
};
</script>

<template>
  <div>
    <b-modal v-model="showLocal" size="lg" hide-footer centered :backdrop="true" @hidden="clearForm">
      <div>
        <b-row>
          <b-col class="text-center">
            <h2>{{ !editMode ? `Create Product Category` : `Edit Product Category` }}</h2>
          </b-col>
        </b-row>
        <hr />
        <b-form @submit.stop.prevent="save">
          <b-row class="mb-2">
            <b-col md="4">
              <label>Product Category</label>
            </b-col>
            <b-col md="8">
              <b-input v-model="payload.description" class="form-control" />
              <small>Product Category name</small>
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <b-col>
              <b-btn block variant="danger" @click.stop="showLocal = false">Cancel</b-btn>
            </b-col>
            <b-col>
              <b-btn block type="submit" variant="success">Save</b-btn>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>
